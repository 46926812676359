import React, { useState } from "react";

import { TextField, IconButton } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { REGEX_MAIL } from "../../config/config";

export default function CustomPasswordField({
  name,
  value,
  sx,
  handleChange,
  label,
}) {
  const [isPasswordShowed, setIsPasswordShowed] = useState(false);

  const handleShowPassword = () => {
    setIsPasswordShowed(!isPasswordShowed);
  };
  return (
    <TextField
      type={isPasswordShowed ? "text" : "password"}
      name={name === 1 ? "password" : "password_confirmation"}
      sx={sx}
      label={label}
      required={true}
      autoComplete="current-password"
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          <IconButton onClick={handleShowPassword}>
            {!isPasswordShowed ? <RemoveRedEyeIcon /> : <VisibilityOffIcon />}
          </IconButton>
        ),
      }}
      value={value}
    />
  );
}
