import { createTheme } from "@mui/material/styles";
import { frFR } from "@mui/material/locale";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const theme = createTheme(
  {
    typography: {
      fontFamily: ["Roboto", "Arial", "sans-serif"].join(","),
      h1: { fontWeight: "700", fontSize: "75px" },
    },
    palette: {
      primary: {
        main: "#F2A71D",
        light: "rgba(242, 167, 29, 0.05)",
        contrast: "#F5F5F5",
        blue: "#1E88E5",
      },
      secondary: {
        main: "#FBD005",
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        tablet: 768,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          contained: {
            color: "#F5F5F5",
            width: "fit-content",
            margin: "auto",
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              color: "#F2A71D",
            },
          },
        },
      },
    },
  },
  frFR,
);

export default theme;
