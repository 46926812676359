import React from "react";
import { Stack, Typography, Container } from "@mui/material";
import { styled } from "@mui/system";

import PaintBrush from "../../assets/paintbrush.svg";
import Stroke from "../../assets/stroke.svg";

const StyledStack = styled(Stack)({
  position: "sticky",
  top: "50%",
  left: "50%",
  zIndex: "5",
  height: "100%",
  width: "100%",
  flex: "1",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  "&::after": {
    display: "block",
    width: "99vw",
    height: "100lvh",
    content: "''",
    position: "absolute",
    top: "0",
    transform: "translate(-129px,-50%)",
    zIndex: "-1",
    background: "rgba(0,0,0,0.2)",
  },

  "& div": {
    position: "relative",
    transform: "translateX(-50%)",
    width: { xs: "250px", md: "400px" },
  },
  "& div #pinceau": {
    position: "absolute",
    width: "46px",
    bottom: "0",
    right: "0",
    animation: "translate 2s linear infinite",
  },
  "& div #text": {
    position: "absolute",
    top: "0",
    left: "50%",
    transform: "translate(-50%,-100%)",
    textShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)",
  },
  "& div #stroke": {
    width: "100%",
    transformOrigin: "left",

    animation: "loading 2s linear infinite",
  },

  "@keyframes loading": {
    "10%": {
      transform: "scaleX(0)",
    },
    "20%": {
      transform: "scaleX(1)",
    },
    "100%": {
      transform: "scaleX(1)",
    },
  },
  "@keyframes translate": {
    "10%": {
      transform: "translateX(-529%)",
    },
    "20%": {
      transform: "translateX(0)",
    },
    "100%": {
      transform: "translateX(0)",
    },
  },
  "@media (max-width: 1199px)": {
    "&::after": {
      transform: "translate(0,-50%)",
    },
    "& div": {
      transform: "translateX(0)",
    },
  },
});

export default function PageLoader() {
  return (
    <StyledStack>
      <Stack component="div">
        <img src={PaintBrush} alt="pinceau" id="pinceau" />
        <Typography id="text" sx={{ color: "primary.contrast" }}>
          Chargement...
        </Typography>
        <img src={Stroke} alt="trace de pinceau" id="stroke" />
      </Stack>
    </StyledStack>
  );
}
