import React from "react";

import { Button, Box } from "@mui/material";

import { Loader } from "../";

export default function CustomButton({ isLoading, buttonText, containerStyle, onClick  = () =>{}, disabled }) {

  return (
    <Box sx={{ width: 'fit-content' }} style={containerStyle}>
      {isLoading ? (
        <Loader style={{ position: "relative" }} />
      ) : (

        <Button
          type="submit" 
          variant="contained" 
          color="primary" 
          size="large" 
          disabled={disabled}
          onClick={(e) => onClick(e)}
        >
          {buttonText}
        </Button>
      )}
    </Box>
  );
}
