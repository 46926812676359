import { Suspense, useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";

import {
  Login,
  Dashboard,
  ChangeOrderStatus,
  SetUpOrder,
  SimulatedOrder,
  ImportOrder,
  OrderHistory,
  Profile,
  Signin,
} from "./pages";
import theme from "./utils/theme/theme.jsx";
import { Layout, PageLoader, Alerts } from "./components";

function App() {
  const user = useSelector((state) => state.user);
  const [isLogged, setIsLogged] = useState(user.isLogged);

  useEffect(() => {
    setIsLogged(user.isLogged);
  }, [user]);

  return (
    <Alerts>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<PageLoader />}>
          {!isLogged && (
            <>
              <Login />
              <Routes>
                <Route path="/signin" exact element={<Signin />} />
              </Routes>
            </>
          )}

          {isLogged && (
            <Layout>
              <Routes>
                <Route path="/" exact element={<Dashboard />} />
                <Route path="/profile/:userId" exact element={<Profile />} />
                <Route
                  path="/history/change_order_status"
                  exact
                  element={<ChangeOrderStatus />}
                />
                <Route path="/new_order" element={<ImportOrder />} />
                <Route
                  path="/new_order/simulated_order/:purchaseOrderId"
                  element={<SimulatedOrder />}
                />
                <Route
                  path="/new_order/setup_order/:purchaseOrderId"
                  element={<SetUpOrder />}
                />
                <Route
                  path="/history/order_history/:orderId"
                  element={<OrderHistory />}
                />
              </Routes>
            </Layout>
          )}
        </Suspense>
      </ThemeProvider>
    </Alerts>
  );
}

export default App;
