import React, { useEffect, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { styled } from '@mui/system';


import { FormControl, Typography } from "@mui/material";

import UploadFileIcon from "@mui/icons-material/UploadFile";
import CheckIcon from "@mui/icons-material/Check";

import theme from "../../utils/theme/theme.jsx";
import { useFetch } from "../../utils/hooks";
import { PURCHASE_ORDERS_URI } from "../../config/config";
import { PURCHASE_ORDER } from "../../stores/actions";
import { CustomButton } from '../'

const StyledLabel = styled('label')({
    border: `1px dashed ${theme.palette.primary.main}`,
    borderRadius: "4px",
    background: `${theme.palette.primary.light}`,
    width: "100%",
    height: "152px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "10px",
    padding: "0 10px",

    '&:hover':{
      cursor: 'pointer',
    },
});


export default function CustomInsertFile({ style, isMarginBLarge }) {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState("");

  const { isLoading, error, responseData, resStatus, post } = useFetch(true);

  const onSimulate = (e) => {
    e.preventDefault();
    const file = new FormData(e.target);
    post(PURCHASE_ORDERS_URI, file, false);
  };

  useEffect(() => {
    if (resStatus === 201 && !error && responseData) {
      dispatch({ type: PURCHASE_ORDER, data: responseData });
      navigate(`/new_order/simulated_order/${responseData.purchase_order.id}`);
    }
  }, [responseData]);

  //---------------------------------------------

  const isFilePresent = () => {
    if (fileName) {
      return false
    } else{
      return true
    }
  }

  return (
    <FormControl
      component="form"
      sx={{ width: "100%", maxWidth: "600px", position: "relative" }}
      style={style}
      onSubmit={(e) => onSimulate(e)}
    >
      <StyledLabel htmlFor="import_document_order" style={{ marginBottom: isMarginBLarge ? "30px" : "10px" }}>
        <UploadFileIcon color="primary" />

        {fileName ? (
          <Typography variant="subtitle2" mt="10px">
            <CheckIcon color="success" /> {fileName}
          </Typography>
        ) : (
          <>
            <Typography variant="subtitle2" mt="10px">
              <span
                style={{
                  color: theme.palette.primary.main,
                  textDecoration: "underline",
                }}
              >
                Importer
              </span>{" "}
              ou glisser - deposer votre fichier
            </Typography>
            <Typography variant="body2">PDF ou CSV (max. 10Mo)</Typography>
          </>
        )}
      </StyledLabel>
      <input
        type="file"
        id="import_document_order"
        style={{ display: "none" }}
        accept=".csv, .pdf"
        name="document_order"
        maxLength="10485760"
        onChange={(e) => {
          if (e.target.files[0]?.name) {
            setFileName(e.target.files[0].name);
          }else{
            setFileName('')
          }
          
        }}
      />
      <CustomButton isLoading={isLoading} buttonText={"Importer ma commande"} disabled={isFilePresent()} containerStyle={{margin:'auto'}} />
    </FormControl>
  );
}
