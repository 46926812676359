import React, { useEffect } from "react";

import Underline from "../../assets/underline.svg";

import {
  Stack,
  Typography,
  Container,
  Link,
  Breadcrumbs,
  Grid,
} from "@mui/material";
import { Image } from "mui-image";
import StarIcon from "@mui/icons-material/Star";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useParams } from "react-router-dom";

export default function CustomBoard({
  title,
  children,
  style,
  orderRef,
  isBreadCrumbs,
}) {
  const purchaseOrderId = useParams().orderId;

  return (
    <Container
      maxWidth="xl"
      sx={{ height: "100%", padding: { xs: "0 2%", sm: "0 24px" } }}
    >
      <Grid container>
        <Grid item xs={12} xl={12}>
          <Stack sx={{ padding: "20px 0" }}>
            <Typography
              variant="h1"
              sx={{ fontSize: "clamp(4rem, 3vw, 4.7rem)" }}
            >
              {title}
            </Typography>
            {isBreadCrumbs && (
              <Typography
                variant="body2"
                color="text.secondary"
                component="div"
              >
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <Link
                    underline="hover"
                    key="1"
                    color="inherit"
                    href="/history/change_order_status"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <ViewHeadlineIcon /> Historique
                  </Link>
                  ,
                  <Link
                    underline="hover"
                    key="1"
                    color="inherit"
                    href={`/history/order_history/${purchaseOrderId}`}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <StarIcon sx={{ fontSize: "18px" }} /> {orderRef}
                  </Link>
                </Breadcrumbs>
              </Typography>
            )}

            <Image
              src={Underline}
              alt="soulignage du titre Dashboard"
              style={{
                maxWidth: "100%",
                animation: "unset",
                transition: "unset",
                width: "auto",
              }}
              wrapperStyle={{ justifyContent: "start" }}
            />
          </Stack>

          <Stack
            spacing={5}
            alignItems="center"
            justifyContent="center"
            sx={{
              minHeight: "80vh",
              width: "86%",
              margin: "auto",
              paddingBottom: 4,
            }}
          >
            {children}
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}
