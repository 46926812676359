import React from "react";
import { Paper, Typography, Stack } from "@mui/material";

import { CustomStatus, Loader } from "../";

export default function CustomPaper({
  title,
  children,
  sx,
  subtitle,
  status,
  isLoading,
}) {
  const paperStyle = {
    position:'relative',
    borderRadius: "10px",
    minHeight: "367px",
    maxWidth:"90vw",
    width:"100%",
    overflow:"auto",
    padding: "20px",
    boxShadow: "4px 4px 4px rgba(0,0,0,0.25)",
  };

  return (
        <Paper sx={paperStyle} style={sx} variant="outlined">
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5" sx={subtitle && { padding: "16px" }}>
              {title}
              {subtitle && <Typography>{subtitle}</Typography>}
            </Typography>
            {status && <CustomStatus>{status}</CustomStatus>}
          </Stack>
          {isLoading ? <Loader /> : children}
        </Paper>
  );
}
