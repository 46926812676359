import React from "react"

import { Typography } from "@mui/material"

import theme from "../../utils/theme/theme.jsx"

export default function CustomStatus({ children }) {

	const colorStatus = {
		créée:theme.palette.warning.main, 
		simulée : theme.palette.warning.main, 
		commandée: theme.palette.primary.blue, 
		livrée: theme.palette.success.main
	}

	const CustomTypoStyle = {
		background: `${colorStatus[children]}`,
		borderRadius: "100px",
		height: "26px",
		padding: "3px 5px",
		width: "fit-content",
		color: `${theme.palette.primary.contrast}`,
	}

	function capitalizeFirstLetter(string) {
	    if (typeof string !== 'string' || string.length === 0) {
	        return string;
	    }
	    return string.charAt(0).toUpperCase() + string.slice(1);
	}

	return (
		<Typography variant="body2" sx={CustomTypoStyle}>
			{capitalizeFirstLetter(children)}
		</Typography>
	)
}
