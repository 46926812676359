import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { Image } from "mui-image";

import Logo from "../../../assets/lpi_logo.svg";

import { LOGOUT } from "../../../stores/actions";
import { COOKIE_ID } from "../../../config/config";

import {
  Stack,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Paper,
} from "@mui/material";

import HomeIcon from "@mui/icons-material/Home";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";

export default function SideNav({ openMenu, setOpenMenu }) {
  const [selectedListItem, setSelectedListItem] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = Cookies.get(COOKIE_ID);

  const handleListItemClick = (e, i) => {
    setSelectedListItem(i);
    setOpenMenu(!openMenu)
  };

  const onLogout = () => {
    dispatch({ type: LOGOUT });
    toast.success("Vous êtes déconnecté", { autoClose: 2000 });
    navigate("/");
  };

  const location = useLocation();

  const deleteParamsId = (path) => {
    const match = path.match(/^\/([^\/]+)\/([^\/]+)/);
    if (match) {
      return match[1];
    } else {
      const parts = path.split("/");
      if (parts.length > 1) {
        return parts.slice(1).join("/");
      } else {
        return "";
      }
    }
  };

  useEffect(() => {
    const path = deleteParamsId(location.pathname);

    switch (path) {
      case "dashboard":
        setSelectedListItem(0);
        break;

      case "new_order":
        setSelectedListItem(1);
        break;

      case "history":
        setSelectedListItem(2);
        break;

      case "profile":
        setSelectedListItem(3);
        break;

      case "logout":
        setSelectedListItem(4);
        break;

      default:
        setSelectedListItem(0);
        break;
    }
  }, [location.pathname]);

  //---------------------------------------------

  const globalContainerStyle = {
    position: "absolute",
    zIndex: "2",
    width: "100%",
    height: "100%",
    display: { xs: openMenu ? "block" : "none", lg: "none" },
    background: "rgba(255,255,255,0.7)",
    transition: "opacity 0.1s ease-out",
  };

  const menuContainerStyle = {
    width: "256px",
    height: "100%",
    position: "fixed",
    zIndex: "3",
    background: "#ffffff",
    transform: {
      xs: `translateX(${!openMenu ? "-100%" : "0"})`,
      lg: `translateX(0)`,
    },
    boxShadow: "1px 0px 2px rgba(0,0,0,0.25)",
    transition: "transform 0.1s ease-out",
  };

  return (
    <>
      <Box sx={globalContainerStyle} 
        onClick={() => {
          setOpenMenu(!openMenu);
        }}></Box>
      <Box>
        <Stack
          justifyContent="space-between"
          alignItems="center"
          sx={menuContainerStyle}
        >
          <Box>
            <Image
              src={Logo}
              alt="logo la petite entreprise"
              sx={{ maxWidth: "100%", padding: "50px 40px" }}
              fit="contain"
              height="auto"
              duration={500}
            />
            <List>
              <ListItemButton
                selected={selectedListItem === 0}
                onClick={(e) => {
                  handleListItemClick(e, 0);
                  navigate("./");
                }}
              >
                <ListItemIcon sx={{ color: "inherit" }}>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItemButton>

              <ListItemButton
                selected={selectedListItem === 1}
                onClick={(e) => {
                  handleListItemClick(e, 1);
                  navigate("./new_order");
                }}
              >
                <ListItemIcon sx={{ color: "inherit" }}>
                  <ShoppingCartIcon />
                </ListItemIcon>

                <ListItemText primary="Nouvelle commande" />
              </ListItemButton>

              <ListItemButton
                selected={selectedListItem === 2}
                onClick={(e) => {
                  handleListItemClick(e, 2);
                  navigate("./history/change_order_status");
                }}
              >
                <ListItemIcon sx={{ color: "inherit" }}>
                  <ViewHeadlineIcon />
                </ListItemIcon>

                <ListItemText primary="Historique" />
              </ListItemButton>
            </List>
          </Box>

          <List style={{ width: "100%" }}>
            <ListItemButton
              selected={selectedListItem === 3}
              onClick={(e) => {
                handleListItemClick(e, 3);
                navigate(`./profile/${userId}`);
              }}
            >
              <ListItemIcon sx={{ color: "inherit" }}>
                <AccountCircleIcon />
              </ListItemIcon>

              <ListItemText primary="Profil" />
            </ListItemButton>

            <ListItemButton
              selected={selectedListItem === 4}
              onClick={(e) => {
                handleListItemClick(e, 4);
                onLogout();
              }}
            >
              <ListItemIcon sx={{ color: "inherit" }}>
                <LogoutIcon />
              </ListItemIcon>

              <ListItemText primary="Déconnexion" />
            </ListItemButton>
          </List>
        </Stack>
      </Box>
    </>
  );
}
