import { Image } from "mui-image";

import Logo from "../../assets/lpi_logo.svg";
import Spools from "../../assets/spools.png";

import {
  Container,
  Stack,
} from "@mui/material";

export default function CustomLandPage({children}) {

  const spoolsStyle = {
    backgroundSize: "cover",
    backgroundImage: `url(${Spools})`,
    backgroundPosition: "center",
    borderRadius: "20px",
    margin: "0 42px",
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-around"
      py={2}
      sx={{ minHeight: "100vh" }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={10}
        px={{ xs: 2, md: 6}}
        sx={{ width: "100%", minWidth:"320px", maxWidth:"510px" }}
      >
        <Image
          src={Logo}
          alt="logo la petite entreprise"
          fit="contain"
          height="auto"
          width="100%"
          duration={500}
        />

        {children}
      </Stack>

      <Container maxWidth={false} style={spoolsStyle} sx={{display: {xs: "none", md:"block"}}}></Container>
    </Stack>
  );
}
