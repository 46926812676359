import React from "react"

import Background from "../../../assets/bgd.svg"
import theme from "../../../utils/theme/theme.jsx"

import { Stack, Container } from "@mui/material"

export default function Board({ children }) {
	const boardBackground = {
		backgroundImage: `url(${Background})`,
		backgroundSize: "100%",
		backgroundColor: theme.palette.primary.light,
		minHeight: "100vh",
		width: "100%", 
		gridColumn: {xs: '1', lg:'2/3'},
	}
	return (
		<Stack direction="row" sx={boardBackground}>
			{children}
		</Stack>
	)
}
