import React, { useState } from "react";
import { TextField } from "@mui/material";

import { REGEX_MAIL } from "../../config/config";

export default function CustomEmailInput() {
  const [isEmailValid, setIsEmailValid] = useState(true);

  const checkEmail = (e) => {
    setIsEmailValid(REGEX_MAIL.test(e.target.value));
  };
  return (
    <TextField
      label="Email"
      variant="outlined"
      name="email"
      type="email"
      size="medium"
      autoComplete="email"
      required={true}
      error={!isEmailValid}
    />
  );
}
