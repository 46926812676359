import React from "react";

import { Stack, CircularProgress } from "@mui/material";

export default function Loader({ style }) {
  return (
    <Stack
        sx={{
            position:"absolute",
            top:"50%", 
            left:'50%', 
            transform:'translate(-50%, 0%)',
            zIndex: '5',
            width:'fit-content',
          }}
        style={style}
    >
      <CircularProgress color="primary" />
    </Stack>
  );
}
