import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { Stack, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import SideNav from "./side_nav";
import Background from "./background";

export default function Layout({ children }) {
  const [openMenu, setOpenMenu] = useState(false);

  const menuIconStyle = {
    position: "fixed",
    top: "0",
    left: "0",
    zIndex: "5",
    display: { lg: "none" },
  };

  const globalContainerStyle = {
    position:"relative",
    display:'grid',
    gridTemplateColumns: {xs: '1fr', lg: '256px 1fr'},
    maxWidth:'100%',
  }
  return (
    <Stack direction="row" sx={globalContainerStyle}>
      <IconButton
        sx={menuIconStyle}
        size="large"
        color="primary"
        onClick={() => {
          setOpenMenu(!openMenu);
        }}
      >
        {openMenu ? <CloseIcon /> : <MenuIcon />}
      </IconButton>
      <SideNav openMenu={openMenu} setOpenMenu={setOpenMenu} />
      <Background>{children}</Background>
    </Stack>
  );
}
