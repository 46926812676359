import { lazy } from "react"

const Login = lazy(() => import("./login"))
const Dashboard = lazy(() => import("./dashboard"))
const ChangeOrderStatus = lazy(() => import("./change_order_status"))
const ImportOrder = lazy(() => import("./import_order"))
const SimulatedOrder = lazy(() => import("./simulated_order"))
const SetUpOrder = lazy(() => import("./setup_order"))
const NewOrder = lazy(() => import("./new_order"))
const OrderHistory = lazy(() => import("./order_history"))
const Profile = lazy(() => import("./profile"))
const Signin = lazy(() => import("./signin"))

export {
	Login,
	Dashboard,
	ChangeOrderStatus,
	ImportOrder,
	SimulatedOrder,
	SetUpOrder,
	NewOrder,
	OrderHistory,
	Profile,
	Signin
}
