import { configureStore, combineReducers } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { COOKIE_TOKEN, COOKIE_ID } from "../config/config";
import { LOGIN, LOGOUT, GET_USER, UPDATE, PURCHASE_ORDER } from "./actions";

const initialUserData = {
  id: Cookies.get(COOKIE_ID) || null,
  username: null,
  email: null,
  isLogged: Boolean(Cookies.get(COOKIE_TOKEN)),
};

const userReducer = (state = initialUserData, payload) => {
  const { type, data, token } = payload;

  switch (type) {
    case GET_USER:
      if (!data || !data.id) {
        throw new Error("Data for login must not be empty.");
      }
      return {
        id: parseInt(data.id),
        username: data.username,
        email: data.email,
        isLogged: true,
      };
    case LOGIN:
      if (!data || !data.id) {
        throw new Error("Data for login must not be empty.");
      }
      if (!token) {
        throw new Error("Missing token.");
      }

      Cookies.set(COOKIE_TOKEN, token, {
        sameSite: "none",
        secure: true,
      });
      Cookies.set(COOKIE_ID, data.id, {
        sameSite: "none",
        secure: true,
      });
      return {
        id: parseInt(data.id),
        username: data.username,
        email: data.email,
        isLogged: true,
      };
    case UPDATE:
      if (!data || !data.id) {
        throw new Error("Data for login must not be empty.");
      }
      return {
        id: parseInt(data.id),
        username: data.username,
        email: data.email,
        isLogged: true,
      };
    case LOGOUT:
      Cookies.remove(COOKIE_TOKEN);
      Cookies.remove(COOKIE_ID);
      return {
        id: null,
        username: null,
        email: null,
        isLogged: false,
      };
    default:
      return state;
  }
};

const initialPurchaseOrder = { purchase_order: {}, products: {} };

const purchaseOrderReducer = (state = initialPurchaseOrder, payload) => {
  const { type, data } = payload;

  switch (type) {
    case PURCHASE_ORDER:
      if (!data) {
        throw new Error("Data for import order must not be empty.");
      }
      return {
        purchase_order: data.purchase_order,
        products: data.products,
      };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  user: userReducer,
  purchaseOrder: purchaseOrderReducer,
});

export default configureStore({
  reducer: rootReducer,
});
